<template>
  <a-spin class="loadingClass" v-if="dataValue.loading.type === true" :tip="dataValue.loading.title"></a-spin>
  <div v-if="dataValue.loading.type === false" style="padding:15px;margin-bottom: 20px;">

    <template v-for="row in dataValue.listHttp.output.list">
      <div class="dRow">
        <div class="aIndex">{{row['mailIndexId']}}、{{row['mail']}}</div>
        <label class="lTitle">标题：{{ row['title'] }}</label>
      </div>
      <div class="dInfo" v-if="row['mailIndexId'] === 1">
        {{row['info']}}
        <div class="ts">(* 只显示第一条邮件的邮件内容。)</div>
      </div>
    </template>
  </div>

</template>

<script>
import {reactive} from "vue";
import httpRequest from "@/utils/axios";

export default {
  name : "examine_show",
  props : [ 'row_obj' ],
  emits : [ 'goClose' ],
  setup(props, { emit }) {

    let dataValue = reactive({
      loading : {
        type : false,
        title : ""
      },
      ic7MailGroupId : props.row_obj['ic7MailGroupId'],
      listHttp : {
        input : {
          ic7MailGroupId : props.row_obj['ic7MailGroupId']
        },
        output : {
          allCount : 0,
          list : []
        }
      }
    })

    let allEvent = {

      listHttp : () => {

        // console.log(dataValue.listHttp.input)

        dataValue.loading.type = true;
        dataValue.loading.title = "正在加载数据 ......";
        httpRequest.post('/admin/examine/mail.examine.show', dataValue.listHttp.input).then((res) => {
          if (res.code === 200) {
            dataValue.listHttp.output.allCount = res.data.allCount;
            dataValue.listHttp.output.list = res.data.list;
          }
          dataValue.loading.type = false;
        })

      },

      init : () => {
        allEvent.listHttp();
      }
    }
    allEvent.init()

    return { dataValue, allEvent }
  }
}
</script>

<style scoped>
.loadingClass { width: 100%; text-align: center; padding-top: 100px; }
.dRow{ line-height: 30px; }
.dRow .aIndex{ width: 300px; }
.dRow .lTitle{ color: #666666;padding-left: 50px; }
.dInfo{ line-height: 30px;margin-left: 50px; margin-bottom: 30px; color: #666666; }
.ts{ margin: 10px 0 10px 0; color: firebrick }
</style>