<template>
  <a-spin class="loadingClass" v-if="dataValue.loading.type === true" :tip="dataValue.loading.title"></a-spin>
  <div v-if="dataValue.loading.type === false" style="padding:15px;margin-bottom: 20px;">
    <div v-if="dataValue.examineList.output.list.length === 0" class="noRow">未有待审批的数据 ... ...</div>
    <table class="ahuiTable" v-if="dataValue.examineList.output.list.length > 0">
      <tr>
        <th>序号</th>
        <th>申请人</th>
        <th>计划发送时间</th>
        <th>邮件数</th>
        <th>审批状态</th>
        <th>审批时间</th>
        <th>邮件标题</th>
        <th>创建人</th>
        <th>明细</th>
        <th>审批</th>
      </tr>
      <tr v-for="aRow in dataValue.examineList.output.list">
        <td class="min">{{ aRow['ic7MailGroupId'] }}</td>
        <td class="min">{{ aRow['employeeName'] }}</td>
        <td class="min">{{ aRow['planTime'] }}</td>
        <td class="min">{{ aRow['mailCount'] }}</td>
        <td class="min">{{ aRow['examineName'] }}</td>
        <td class="min">{{ aRow['examineTime'] }}</td>
        <td>{{ aRow['tempMailTitle'] }}</td>
        <td class="min">{{ aRow['createTime'] }}</td>
        <td class="min">
          <a @click="allEvent.showMail.open(aRow)">查看</a>
        </td>
        <td class="min exTd">
          <a-popconfirm title="请确认要同意当前邮件发送？" ok-text="是" cancel-text="否" @confirm="allHttp.examine(aRow,1)">
            <a>同意</a>
          </a-popconfirm>
          <a-popconfirm title="请确认要拒绝当前邮件发送？申请记录将被删除！" ok-text="是" cancel-text="否" @confirm="allHttp.examine(aRow,2)">
            <a>拒绝</a>
          </a-popconfirm>
        </td>
      </tr>
    </table>
  </div>

  <a-drawer
      title="查看待发邮件的列表 - （最多显示100条）"
      width=950
      :visible="dataValue.showMail.visible"
      placement="right"
      :destroyOnClose="true"
      @close="allEvent.showMail.close" >
    <showPage :row_obj="dataValue.showMail.rowObj" @goClose="allEvent.showMail.close" />
  </a-drawer>

</template>

<script>
import {reactive} from "vue";
import httpRequest from "@/utils/axios";
import showPage from "@/view/stock/examine_show"

export default {
  name : "examine_order",
  emits : [ 'setMenuKey' ],
  components : { showPage },
  setup(props, { emit }) {
    emit('setMenuKey', 'kc.spdfxx');

    let dataValue = reactive({

      showMail : {
        rowObj : null,
        visible : false
      },

      loading : {
        type : false,
        title : ""
      },

      examineList : {
        input : {
          examineTypeId : -1,
        },
        output : {
          allCount : 0,
          list : []
        }
      }

    });

    let allHttp = {

      examine : (row, type) => {

        let input = {
          ic7MailGroupId : row['ic7MailGroupId'],
          examineTypeId : type
        }

        dataValue.loading.type = true;
        dataValue.loading.title = "正在删除数据 ......";
        httpRequest.post('/admin/examine/mail.examine', input).then((res) => {
          if (res.code === 200) {
            allHttp.examineList();
          }
          dataValue.loading.type = false;
        })

      },

      examineList : () => {

        dataValue.loading.type = true;
        dataValue.loading.title = "正在加载数据 ......";
        httpRequest.post('/admin/examine/mail.examine.list', dataValue.examineList.input).then((res) => {
          if (res.code === 200) {
            dataValue.examineList.output.allCount = res.data.allCount;
            dataValue.examineList.output.list = res.data.list;
          }
          dataValue.loading.type = false;
        })

      },

      init() {
        allHttp.examineList();
      }

    }

    allHttp.init();

    let allEvent = {

      showMail : {

        open : (row) => {
          dataValue.showMail.visible = true;
          dataValue.showMail.rowObj = row;
        },

        close : () => {
          dataValue.showMail.visible = false;
        }

      }

    }

    return { dataValue, allHttp, allEvent }
  }
}
</script>

<style scoped>
.loadingClass { width: 100%; text-align: center; padding-top: 100px; }
.exTd a { margin: 0 3px 0 3px; }
.noRow { text-align: center; color: #225DDC; }
</style>